.dashboard-container {
    
    display: flex;
    flex-direction: column;
   
}
.dashboard-name {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: .5rem;
}
.dashboard-name h3{
    color: var(--azul);
}
.dashboard-cards {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    gap: 2rem 1rem;
    flex-direction: column;
}
.dashboard-actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;
}
.dashboard-actions .btn-container {
    position: relative;
}
.dashboard-actions .btn-container .notification {
    width: 15px;
    height: 15px;
    background: red;
    border-radius: 11rem;
    position: absolute;
    top: -5px;
    right: 0;
}
.dashboard-cards.hide{
    display: none;
}
.card-container {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    border-left: 5px solid #52527b;
}
.card-container form.sign-up-form{
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.card-container form select{
    padding: .4rem .5rem;
    border-radius: 1rem;
}

.inputfield.selection-radio {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: .5rem 0;
}
.date-card-container{
    
text-align: center;
    
display: flex;
    
flex-direction: row;
    
flex-wrap: wrap;
    
justify-content: space-around;
    
row-gap: 2rem;
}
.date-card-container .title{
    
}
.date-card {
    /* text-align: -webkit-left; */
    background: var(--azul-cards);
    padding: 2rem 1rem;
    border-radius: 1rem;
    box-shadow: 1px 2px 4px 0px #a6a6a6;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    min-width: 400px;
    max-width: 1000px;
    margin: auto;
}
.date-card-text{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    gap: 1rem;
}
.date-card-text h3,.date-card-text p {
    margin: .2rem;
}
.date-card .actions{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.cedula{
    position: absolute;
    top: -7px;
    left: 1rem;
    color: var(--oro);
    border-bottom: 1px solid;
    width: fit-content;
    font-size: large;
}

.btn-active{
    background-color: var(--azul-active);
    color: var(--azul);
    transition: all .4s ease;
}

.form-field {
    flex-direction: row;
    align-items: baseline;
    position: relative;
    /* height: 30px; */
}
.form-field input{
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: 2px solid #b9b9b9;
    width: 100%;
    outline: none;
    outline-offset: 0;
}
.search-date .btn-primary,
.search-date-2 .btn-primary{
    margin: 0;
    position: absolute;
    right: 0;
    padding: 0.6rem 1rem;
    height: 100%;
    border-radius: 0 1rem  1rem 0;
}
.status-container {
    position: absolute;
    bottom: 0;
    right: 1rem;
    display: flex;
    gap: .5rem;
    align-items: baseline;
}
.status-container .dot{
    width: 10px;
    height: 10px;
    border-radius: 2rem;
}
.status-container .dot.green{
    background-color: green;
}
.status-container .dot.red{
    background-color: red;
}
.super-admin{
    display: flex;
    gap: 1rem;
}
.card-superadmin{
    background-color: var(--azul);
    color: white;
    max-width: 700px;
    width: auto;
}
.card-superadmin .form-field{
   margin: 0;
}
.card-superadmin .btn-primary{
    background-color: var(--azul-claro);
    color: white;
}
.super-admin .card-superadmin .form-field label{
    color: white;
    text-transform: none;
    margin: 0;
    font-weight: 300;
}
.super-admin .card-superadmin .form-field input{
    text-transform: none;

}
.pendingSwitch {
    height: 30px;
    display: flex;
}
.complete, .pending{
    flex: 1;
    border: 1px solid;
    background: #9bc5e4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: .8rem;
    cursor: pointer;
}
.complete.active, .pending.active{
    border: 2px solid;
    background: #002b49;
    font-size: 1rem;
}